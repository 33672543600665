import React from "react"
import "../assets/styles/index.scss"
import Head from "../components/global/head"

import banner2x from "../assets/images/edgell/banner@2x.jpg"

import bigscreen1 from "../assets/images/edgell/bigscreen1.jpg"
import block1 from "../assets/images/edgell/block1.jpg"
import block2 from "../assets/images/edgell/block2.jpg"
import block3 from "../assets/images/edgell/block3.jpg"
import block4 from "../assets/images/edgell/block4.jpg"

import phone1 from "../assets/images/edgell/phone1.png"
import phone2 from "../assets/images/edgell/phone2.png"

import recipeBlock1 from "../assets/images/edgell/recipe-block1.jpg"
import recipeBlock2 from "../assets/images/edgell/recipe-block2.jpg"
import recipeBlock3 from "../assets/images/edgell/recipe-block3.jpg"
import recipeBlock4 from "../assets/images/edgell/recipe-block4.jpg"
import recipeBlock5 from "../assets/images/edgell/recipe-block5.jpg"
import recipeBlock6 from "../assets/images/edgell/recipe-block6.jpg"

import screen1 from "../assets/images/edgell/screen1.png"
import screen3_1 from "../assets/images/edgell/screen3-1.png"
import screen3_2 from "../assets/images/edgell/screen3-2.png"
import screen3_3 from "../assets/images/edgell/screen3-3.png"

const Edgell = () => {
  return (
    <>
      <Head
        description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        title="Case Study - Edgell +1"
        url="https://hardhat.com.au/edgell"
        image="https://hardhat.com.au/images/og.jpg"
        bodyClassName="old-case-studies"
      />
      <div>
        <div className="container case-study dark-header">
          <section className="hhd-head-banner js-banner-intro-animation">
            <h1>EDGELL +1</h1>
            <div className="divider black center" />
          </section>
          <section className="hhd-head-banner no-padding js-banner-intro-animation">
            <div
              className="banner-image"
              style={{
                backgroundImage: `url(${banner2x})`,
              }}
            >
              EDGELL +1
            </div>
          </section>
          <section className="hhd-case-intro js-fade-in-animation">
            <div className="hhd-case-intro-text">
              <p>
                A content lead strategy that’s set new standards in how FMCG
                brands ‘do digital’. Each month, we hand-pick and remake a
                selection of popular recipes with the addition of one extra
                veggie. The content is published on the Edgell +1 blog, reworked
                and distributed across the spectrum of relevant social channels.{" "}
              </p>
              <p>
                Dinner, along with huge reach, deep engagement, blogger
                collaboration and a double digit lift in market share, is
                served.
              </p>

              <hr />
            </div>
            <div className="hhd-case-awards">
              <div>
                AIMIA Award finalist: Best Use of Social, Mobile or Content
                (Retail and Consumer Goods)
              </div>
            </div>
            <a
              href="https://mumbrella.com.au/edgell-gets-edgy-as-100-year-old-brand-looks-to-modernise-with-plus-one-social-experiment-295428"
              target="_blank"
              className="case-study-more--external button black button-partnership no-wrapper"
              data-text="-"
            >
              Read the feature on Mumbrella
            </a>
          </section>
          <section className="hhd-case-image-block js-fade-in-animation">
            <div className="block-half">
              <img src={`${block4}`} width="100%" />
            </div>
            <div className="block-half">
              <div className="block-half">
                <img src={`${block1}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block2}`} width="100%" />
              </div>
              <div className="block-full">
                <img src={`${block3}`} width="100%" />
              </div>
            </div>
          </section>
          <section className="hhd-case-screen-breakout js-fade-in-animation">
            <img src={`${screen1}`} width="100%" />
          </section>
          <section className="hhd-case-screen-breakout js-fade-in-animation bottom-padding">
            <div className="hhd-case-screen-wapper">
              <img src={`${recipeBlock1}`} />
              <img src={`${recipeBlock2}`} />
              <img src={`${recipeBlock3}`} />
              <img src={`${recipeBlock4}`} />
              <img src={`${recipeBlock5}`} />
              <img src={`${recipeBlock6}`} />
            </div>
          </section>
          <section className="hhd-case-phone-breakout js-fade-in-animation bottom-padding">
            <img src={`${phone1}`} />
            <img src={`${phone2}`} />
          </section>
          <section className="hhd-case-screen-breakout js-fade-in-animation">
            <div className="hhd-case-image-block">
              <div className="block-half">
                <img src={`${screen3_2}`} width="100%" />
                <img src={`${screen3_3}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${screen3_1}`} width="100%" />
              </div>
            </div>
          </section>
          <section className="hhd-case-bigscreen js-fade-in-animation">
            <img src={`${bigscreen1}`} width="100%" />
          </section>
        </div>
      </div>
    </>
  )
}
export default Edgell
